.edit-container {
    margin-bottom: 20px;

    .btn-container {
        display: flex;
        justify-content: center;

        .btn {
            background:#73B0B6;
            color:#fff;
            text-decoration: none;
            padding:5px 30px;            
            border:none;
            text-transform: uppercase;
            margin: 0 1rem;
            -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
            filter: alpha(opacity=20);
            opacity: 0.2;                    

            &.-active {
                background: #006F79;
                -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
                filter: alpha(opacity=100);
                opacity: 1.0;
            }
        }
    }

    .MuiFormControl-root {
        width: 100%;        
    }

    .form-row {
        margin-bottom: 40px;
    }

    .select {
        margin-top: 15px;
    }

    .checkbox {
        margin: 25px auto;        
    }

    .edit-button {
        background: #006F79;
        color:#fff;
        text-decoration: none;
        padding:5px 30px;            
        border:none;
        text-transform: uppercase;
        margin: 0 1rem;

        &:hover {
            background:#73B0B6;
        }
        
        &--red {
            background: #800000;                        
        }

        &--red:hover {
            background: #B22222;
        }
    }

    input[type="file"] {
        display: none;
    }
    
    .custom-file-upload {
        border: 1px solid #ccc;
        display: inline-block;
        padding: 6px 12px;
        cursor: pointer;
        width: 100%;
        text-align: center;
    }
    
    .preview-image {
        width: 100%;
        max-height: 200px;
    }
}

