.copy-btn {
    padding: 5px 5px 5px 5px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    display: inline;
}

.font-awesome-icon {
    margin-right: 10px;
    margin-left: 10px;
    color: black;
    &:hover {
      color: #ccc;
    }
}