.showMoreButton {
    border: 1px solid rgb(134,144,149);
    border-radius: 3px;
    background-color: rgb(234,244,249);
}

.datasetDescTable th {
    padding-right:25px;
}

.datasetDescTable tr {
    padding-bottom:20px;
}

.extra-content {
    margin-top: 20px;
}

div>p {
    text-align: left;
}

div>h2 {
    text-align: center;
}