@import "~bootstrap/scss/bootstrap";

@font-face {
  font-family: "Equinor";
  src: 
  url(./../../assets/fonts/Equinor-Regular.eot) format('embedded-opentype'), 
  url(./../../assets/fonts/Equinor-Regular.woff) format('woff'), 
  url(./../../assets/fonts/Equinor-Regular.woff2) format('woff2'), 
  url(./../../assets/fonts/Equinor-Regular.ttf) format('truetype');
}

body {
  font-family: Equinor, arial !important;
}

h1, h2, h3, h4, p {
  font-family: Equinor, arial !important;
}

h1 { font-size: 30px !important; }
h2 { font-size: 24px !important; }
h3 { font-size: 20px !important; }
h4 { font-size: 16px !important; }

.bigWhiteWrapperWithoutShadow {
  background:#fff;
  padding:25px;
  margin-bottom: 20px;
}

.bigWhiteWrapper {
  background:#fff;
  padding:25px;
  margin-bottom: 20px;
  box-shadow:0px 0px 4px rgba(0,0,0,0.2);
}

.title-and-icon {  
  display: flex;
  align-items: center;
  margin-bottom: 20px; 

  h1 {
    margin: 0 !important;
    font-size: 30px !important;
    line-height: 40px !important;
  }

  .font-awesome-icon {
    font-size: 40px;
    margin-right: 10px;
    color: black;
    &:hover {
      color: #ccc;
    }
  }
}

.row-full-width {
  width: 100%;
}

.disabled {
  pointer-events: none;
  opacity: 0.4;
}

.no-display {
  pointer-events: none;
  opacity: 0.4;
  display: none;
}

.float-right {
  float: right;
}

@media (max-width: 767px) {
  /* On small screens, the nav menu spans the full width of the screen. Leave a space for it. */
  body {
      padding-top: 50px;
  }
}
