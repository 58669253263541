.entry-child-container {
    margin-top: 20px;
    margin-left: 30px;
    background-color: #ebf4f9;
    border-radius: 15px;

    .entry-child-content {
        padding: 10px;
    }
}

.entry-child-edit-container {
    margin-top: 20px;
    margin-left: 30px;
}