.forum-entry {
    margin-bottom: 20px;
    padding-bottom: 20px;
    padding-top: 20px;    
}

.icon-color {
    color:#888;
    
    &:hover {
        color: #122738;;
    }
}

.show-more {
    margin-right: 20px;
    line-height: 50px;
}
