.data-strategy-intro {
    text-align: center;
}

.data-strategy-img-container {
    height: 60px;
}

.data-strategy-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 30%;
    max-height: 60px;
}

.data-strategy-span {
    font-weight: bold;
}