footer {
    width: 100%;
    position: fixed;
    bottom: 0;

    .MuiBottomNavigation-root {
        background-color: #243746 !important;
        height: 5rem !important;

        .MuiBottomNavigationAction-root {
            color: #fff !important;
        }

        .MuiBottomNavigationAction-label {
            margin-top: 0.5rem;
        }
    }
}