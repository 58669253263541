.mainContent {
    border-top: 1px solid rgb(204, 204, 204);
    border-bottom: 1px solid rgb(204, 204, 204);
    background-color: rgb(235, 244, 249);
    padding:25px;    

    h1 {
        font-size:20px;
    }
}

.visible {
    display: block;
}

.non-visible {
    display: none;
}

.bottom-row {
    height: 5rem;
}