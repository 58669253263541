.forum-header {
    font-size: 20px !important;
    border: 1px solid #ccc;
    padding: 10px;
}

.header-icon {
    margin-right: 20px;
}

.header-menu {
    margin-bottom: 10px;
    .header-menu-icon {
        margin-right: 10px;
    }
}

.entry-content {
    padding: 5px 10px;
    border: 1px dashed #ccc;
    min-height: 80px;
}

.MuiFormControl-root {
    width: 100%;      
}

.MuiInputBase-formControl {
    margin-bottom: 20px;  
}

.margin-right {
    margin-right: 20px;
}