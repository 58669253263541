.datasets {
    list-style:none;
    margin:0;
    padding:0;

    li.dataset-item {
        background: #fff;
        padding: 20px;
        line-height: normal;
        transition: ease 0.3s;
        min-height: 165px !important;

        &:hover { 
            background:#006F79; 
        }
    }

    li.dataset-item-external-link {
        padding: 15px;
        background: #fff;
        margin-bottom: 25px;
        margin-top: -23px;
    }

    a {
        color:#000; 
        text-decoration: none; 
        padding-right:5px;
        
        &:hover { 
            color:#fff; 
        }
    }

    p { 
        margin:0; 
        padding-bottom:10px; 
    }
    
    .title { 
        font-size:1.6rem;
    }

    .contributor { 
        font-size:1.2rem;
    }

    .date { 
        font-size:1.2rem;
    }

    .dataset-image-container {
        overflow: hidden;
        .dataset-image {
            max-height: 200px;
        }
        // padding: 0.5rem;
        // margin-right: 10px;
        // float: left;
        // max-width: 200px;
        // margin-right: 30px;
        // max-height: 100px;
        // margin-bottom: 20px;
    }

    .dataset-content {
        padding-left: 1.0rem;
    }

    .external-data-links {
        // margin-bottom: 20px !important;
        text-align: right;
    }
    
    .external-data-link {
        background-color: #fff;
        color: #000 !important;
        padding: 10px !important;
        font-weight: 500;
        margin-left: 10px;

        &:hover {
            color: #ccc !important;
            background-color: #243746 !important;
        }
    }
}






