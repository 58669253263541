.redirect-btn {
    background: #006F79;
    padding:5px 30px 5px 30px;
    color:#fff;
    border:none;
    text-transform: uppercase;

    &:hover, &:active {
        background:#73B0B6;
        color:#fff;
        text-decoration: none;
    }
  }