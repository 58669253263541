.header {
    min-height: 85px;

    .nav-item {
        padding: 10px;
        height: 52px;
        line-height: 52px;
        vertical-align: middle;
        display: flex;
        align-items: center;
    }

    .logo img {
        margin-bottom:20px;
        margin-top:20px;
        max-height: 80px;
    }
    
    .navContainer {
        margin-top: 45px;
        color: #888;
        display: flex;
        // float: right;

        a:hover, a:visited {
            text-decoration: none;
        }

        a {
            color: #888;
            display: flex;
            align-items: center;
        }

        p {
            margin: 0 0 0 10px;
            vertical-align: middle;
            line-height: 52px;
            align-items: center;          
        }
    }
    
    .navElements {
        display: inline-block;        
    }

    .currentUser {
        float:right;
        display:flex;
        align-items: center;
        margin-top:32px;

        .circle {
            width:40px;
            height:40px;
            border-radius:40px;
            background:#B2B2B2;
        }
        
        .userName{
            padding-left:5px;
        }   
    }

    .admin-links {
        margin-right: 20px;
        margin-top: 10px;
    }
}

